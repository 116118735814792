<template>
  <div>
    <div style="padding: 50px">
      <p style="font-family: notosans; font-size: 25px">
        USER 계정관리 > 학교등록/수정 > 학교상세
      </p>
      <p
        style="
          font-family: notosans;
          font-size: 14px;
          color: rgb(180, 180, 180);
        "
      >
        견적 발급 또는 결제가 확정된 학교의 정보를 등록/수정합니다.
      </p>
      <div style="margin-top: 30px; font-family: notosansregular">
        <div style="float: left; margin-left: 20px">
          <p style="font-family: notosans">[결제단계]</p>
          <div style="margin-top: 15px; zoom: 0.95">
            <circlestate :paystatus="payStatus"></circlestate>
          </div>
        </div>
        <div style="float: left; margin-left: 600px">
          <p style="font-family: notosans">[계정발급 단계]</p>
          <div style="margin-top: 15px; zoom: 0.95">
            <circlestatesecond
              :accountstatus="accountStatus"
            ></circlestatesecond>
          </div>
        </div>
      </div>
      <div style="margin-top: 150px">
        <p style="font-family: notosans; font-size: 16px; float: left">
          학교 정보
        </p>
        <div style="float: left; margin-left: 30px; margin-top: -1px">
          <div v-if="isschoolmodify == true" style="width: fit-content">
            <v-btn
              @click="updateSchoolInfo()"
              variant="outlined"
              width="65"
              height="27"
              style=""
              class="checkbtn defaultbtn"
            >
              확인
            </v-btn>

            <v-btn
              @click="cancelschoolinfomodify()"
              variant="outlined"
              width="65"
              height="27"
              style="margin-left: 15px"
              class="editbtn defaultbtn"
            >
              취소
            </v-btn>
          </div>

          <v-btn
            v-else
            @click="schoolinfomodify()"
            variant="outlined"
            width="65"
            height="27"
            class="editbtn defaultbtn"
          >
            수정
          </v-btn>
        </div>
      </div>
      <div style="margin-top: 185px">
        <table style="width: 1000px; text-align: center; border-spacing: 0">
          <tr>
            <td class="tabletd">교육청</td>
            <td class="tabletd">학교 이름</td>
            <td class="tabletd">코드</td>
            <td class="tabletd">결제 상태</td>
            <td class="tabletd">계정발급 상태</td>
            <td class="tabletd">요금제</td>
            <td class="tabletd">결제방법</td>
            <td class="tabletd">제휴</td>
            <td class="tabletd tdlast">언어</td>
          </tr>
          <tr>
            <td class="tabletd2">{{ school.office }}</td>
            <td class="tabletd2">{{ school.schoolName }}초등학교</td>
            <td class="tabletd2">{{ school.schoolCode }}</td>
            <td
              @click="gopay()"
              style="cursor: pointer; width: 150px"
              class="tabletd2"
            >
              {{ school.payStatus || "-"
              }}<b
                style="
                  font-size: 9px;
                  float: right;
                  margin-top: 4px;
                  margin-right: 2px;
                "
                >▼</b
              >
              <div
                @click="gopay()"
                v-if="payopen === true"
                style="
                  position: absolute;
                  width: 151px;
                  height: 200px;
                  margin-left: -9px;
                  margin-top: 10px;
                  background-color: white;
                "
              >
                <div v-for="(j, i) in payoptions" :key="i">
                  <div
                    :id="`payoption${i + 1}`"
                    @click="modifypaystatus(payoptions[i])"
                    style="height: 50px; width: 150px"
                  >
                    <p style="padding: 14px">
                      {{ payoptions[i] }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
            <td
              @click="goaccount()"
              style="cursor: pointer; width: 150px"
              class="tabletd2"
            >
              {{ school.accountStatus || "-"
              }}<b
                style="
                  font-size: 9px;
                  float: right;
                  margin-top: 4px;
                  margin-right: 2px;
                "
                >▼</b
              >
              <div
                @click="goaccount()"
                v-if="accountopen == true"
                style="
                  position: absolute;
                  width: 151px;
                  height: 200px;
                  margin-left: -9px;
                  margin-top: 10px;
                  background-color: white;
                "
              >
                <div v-for="(j, i) in accountoptions" :key="i">
                  <div
                    :class="`accountoption${i + 1}`"
                    @click="modifyaccountstatus(accountoptions[i])"
                    style="height: 50px; width: 150px"
                  >
                    <p style="padding: 14px">
                      {{ accountoptions[i] }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
            <td
              @click="planChangePopup()"
              class="tabletd2"
              style="cursor: pointer"
            >
              <p>{{ school.payPlan || "-" }}</p>
              <div class="payPlanContent" v-show="planChangeFlag === true">
                <div
                  class="payPlanContentElement"
                  v-for="(j, i) in plans"
                  :key="i"
                  @click="changePlan(j)"
                >
                  <p style="padding-top: 7px">{{ j }}</p>
                </div>
              </div>
            </td>
            <td class="tabletd2">{{ school.payType || "-" }}</td>
            <td class="tabletd2">{{ school.affiliate || "-" }}</td>
            <td @click="changelanguage()" class="tabletd tdlast">
              {{ languageprint(school.language) }}
            </td>
          </tr>
        </table>
      </div>
      <div style="margin-top: 30px">
        <p style="font-family: notosans; font-size: 16px; float: left">메모</p>
        <div style="float: left; margin-left: 30px; margin-top: -1px">
          <div v-if="ismemomodify === true">
            <v-btn
              @click="updatememo()"
              variant="outlined"
              width="65"
              height="27"
              style=""
              class="checkbtn defaultbtn"
            >
              확인
            </v-btn>
            <v-btn
              @click="cancelmemomodify()"
              variant="outlined"
              width="65"
              height="27"
              style="margin-left: 15px"
              class="editbtn defaultbtn"
            >
              취소
            </v-btn>
          </div>
          <v-btn
            v-else
            @click="memomodify()"
            variant="outlined"
            width="65"
            height="27"
            style=""
            class="editbtn defaultbtn"
          >
            수정
          </v-btn>
        </div>
      </div>
      <div style="margin-top: 65px">
        <textarea
          v-model="memo"
          spellcheck="false"
          style="
            font-size: 13px;
            width: 1000px;
            border: 1px solid black;
            padding: 10px;
            resize: none;
            border-radius: 5px;
          "
          rows="3"
          :readonly="!ismemomodify"
        ></textarea>
      </div>
      <div style="margin-top: 20px">
        <p style="font-family: notosans; font-size: 16px; float: left">
          계약 정보
        </p>
        <div style="float: left; margin-left: 30px; margin-top: -1px">
          <div v-if="iscontractmodify == true">
            <v-btn
              @click="updateSchoolContract()"
              variant="outlined"
              width="65"
              height="27"
              class="checkbtn defaultbtn"
            >
              확인
            </v-btn>

            <v-btn
              @click="cancelcontractmodify()"
              variant="outlined"
              width="65"
              height="27"
              style="margin-left: 15px"
              class="editbtn defaultbtn"
            >
              취소
            </v-btn>
          </div>
          <v-btn
            v-else
            @click="contractmodify()"
            variant="outlined"
            width="65"
            height="27"
            class="editbtn defaultbtn"
          >
            수정
          </v-btn>
        </div>
      </div>
      <div style="margin-top: 55px">
        <table style="width: 1000px; text-align: center; border-spacing: 0">
          <tr>
            <td class="tabletd">년도</td>
            <td class="tabletd">계약 시작일</td>
            <td class="tabletd">계약 만료일</td>
            <td class="tabletd">방학중지여부</td>
            <td class="tabletd">계약 학생수</td>
            <td class="tabletd">현재 학생수</td>
            <td class="tabletd tdlast">학급수</td>
          </tr>
          <tr v-for="(j, i) in contracts" :key="i">
            <td class="tabletd2">{{ j.year }}</td>
            <td
              @click="viewdateon(0)"
              style="cursor: pointer; width: 230px"
              class="tabletd2"
            >
              {{ datetext(j.startDate)
              }}<b
                style="
                  font-size: 9px;
                  float: right;
                  margin-top: 4px;
                  margin-right: 2px;
                "
                >▼</b
              >
              <div
                v-if="viewDate[0]"
                ref="startRef"
                style="position: absolute; z-index: 989; margin-left: -25px"
              >
                <DatePicker
                  :max-date="j.endDate"
                  v-model="j.startDate"
                  color="blue"
                />
              </div>
            </td>
            <td
              @click="viewdateon(1)"
              style="cursor: pointer; width: 230px"
              class="tabletd2"
            >
              {{ datetext(j.endDate)
              }}<b
                style="
                  font-size: 9px;
                  float: right;
                  margin-top: 4px;
                  margin-right: 2px;
                "
                >▼</b
              >
              <div
                v-if="viewDate[1]"
                ref="endRef"
                style="position: absolute; z-index: 989; margin-left: -20px"
              >
                <DatePicker
                  :min-date="j.startDate"
                  v-model="j.endDate"
                  color="blue"
                />
              </div>
            </td>
            <td style="width: 150px" class="tabletd2">
              <div
                @click="vacationon(i)"
                style="
                  width: 100%;
                  height: 100%;
                  text-align: center;
                  cursor: pointer;
                "
              >
                <p>
                  {{ vacationtext(j.vacation)
                  }}<b
                    style="
                      font-size: 9px;
                      float: right;
                      margin-top: 4px;
                      margin-right: 2px;
                    "
                    >▼</b
                  >
                </p>
              </div>
              <div
                v-if="vacationselect == i"
                style="
                  position: absolute;
                  width: 151px;
                  height: 100px;
                  margin-left: -9px;
                  margin-top: 10px;
                  background-color: white;
                "
              >
                <div id="vacationoption1" style="height: 50px; width: 150px">
                  <p @click="vacationstop(j, true)" style="padding: 14px">
                    방학 중지
                  </p>
                </div>
                <div id="vacationoption2" style="height: 50px; width: 150px">
                  <p @click="vacationstop(j, false)" style="padding: 14px">
                    해당 없음
                  </p>
                </div>
              </div>
            </td>
            <td class="tabletd2">{{ j.contractStudentNumber }}</td>
            <td class="tabletd2">{{ j.currentStudentNumber }}</td>
            <td class="tabletd2 tdlast">{{ j.classCount }}</td>
          </tr>
        </table>
      </div>
      <div style="margin-top: 20px">
        <p style="font-family: notosans; font-size: 16px; float: left">
          학급 정보
        </p>
      </div>
      <div style="margin-top: 55px">
        <table style="width: 1000px; text-align: center; border-spacing: 0">
          <tr>
            <td class="tabletd">년도</td>
            <td class="tabletd">학년</td>
            <td class="tabletd">반</td>
            <td class="tabletd">출판사</td>
            <td class="tabletd">학생수</td>
            <td class="tabletd">영어 교사</td>
            <td class="tabletd">담임 교사</td>
            <td class="tabletd tdlast">계정 상세</td>
          </tr>
          <tr v-for="(j, i) in classlist" :key="i">
            <td class="tabletd2">{{ j.year }}</td>
            <td class="tabletd2">{{ j.grade }}</td>
            <td class="tabletd2">{{ j.classNum }}</td>
            <td class="tabletd2">{{ j.publisher }}</td>
            <td class="tabletd2">{{ j.studentCnt }}</td>
            <td class="tabletd2">{{ j.engTeacher }}</td>
            <td class="tabletd2">{{ j.teacher }}</td>
            <td class="tabletd2 tdlast">
              <button
                @click="viewclassdetail(j.grade, j.classNum, i)"
                class="iddetailbtn"
                :class="{
                  iddetailbtnselected: popupindex === i,
                }"
                ref="target"
                variant="outlined"
                width="60"
              >
                •&nbsp;•&nbsp;•
              </button>
              <div style="position: absolute; margin-left: -600px">
                <classpopup
                  v-if="idlist.length > 0 && popupindex === i"
                  :classInfo="idlist"
                  @closeEvent="popupClose()"
                ></classpopup>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <contactcomponent
        :contactlist="school.contact"
        :contactlist_origin="school.contact"
      ></contactcomponent>
      <estimateview></estimateview>
    </div>
  </div>
</template>

<script>
import circlestate from "../Circlestate.vue";
import circlestatesecond from "../Circlestate2.vue";
import contactcomponent from "../schoolDetail/ContactComponent.vue";
import estimateview from "../schoolDetail/estimateViewComponent.vue";
import classpopup from "../../../popup/ClassInfoPopupComponent2.vue";
import { DatePicker } from "v-calendar";
import dayjs from "dayjs";
import { changeSchoolPayPlan } from "@/api/api-list";

export default {
  async created() {
    const code = this.$route.params.code;
    const res = await this.$store.dispatch("GET_SCHOOL_DETAIL", code);

    const res2 = await this.$store.dispatch("GET_SCHOOL_CLASS_LIST", code);
    this.contracts = await this.$store.dispatch(
      "GET_SCHOOL_CONTRACT_INFO",
      code
    );

    this.memo = await this.$store.dispatch("GET_SCHOOL_MEMO", code);
    this.school = res;
    this.payStatus = res.payStatus;
    this.accountStatus = res.accountStatus;
    this.classlist = res2;
  },
  data() {
    return {
      school: "",
      memo: "",
      payStatus: "",
      accountStatus: "",
      classlist: [],
      idlist: [],
      idmodal: false,
      contracts: {},
      vacationoptions: [
        {
          text: "방학 중지",
          value: true,
        },
        {
          text: "해당 없음",
          value: false,
        },
      ],
      vacationsentence: "",
      vacationvalue: false,
      vacationselect: -1,
      viewDate: [false, false],
      isschoolmodify: false,
      ismemomodify: false,
      iscontractmodify: false,
      payoptions: ["견적발급", "결제확정", "결제진행", "결제완료"],
      accountoptions: ["안내필요", "정보대기", "발급필요", "발급완료"],
      payopen: false,
      accountopen: false,
      popupindex: -1,
      plans: ["general", "premium", "m", "sm", "pm"],
      planChangeFlag: false,
      previousPlan: "",
      planUpdate: "",
    };
  },
  components: {
    circlestate,
    circlestatesecond,
    contactcomponent,
    estimateview,
    DatePicker,
    classpopup,
  },
  methods: {
    popupClose() {
      this.popupindex = -1;
      this.idlist = [];
    },
    planChangePopup() {
      if (!this.isschoolmodify) {
        return;
      } else {
        this.planChangeFlag = !this.planChangeFlag;
        this.previousPlan = this.school.payPlan;
      }
    },
    async changePlan(payPlan) {
      this.previousPlan = this.school.payPlan;
      this.school.payPlan = payPlan;
    },
    async updatememo() {
      const schoolCode = this.$route.params.code;
      const memo = this.memo;

      const data = {
        schoolCode,
        memo,
      };

      const res = await this.$store.dispatch("UPDATE_SCHOOL_MEMO", data);

      if (res) {
        alert("메모가 수정되었습니다.");
        this.ismemomodify = false;
      } else {
        alert("오류가 발생하였습니다.");
      }
    },
    async viewclassdetail(grade, classNum, index) {
      this.idlist = [];
      const schoolCode = this.$route.params.code;
      const data = { schoolCode, grade, classNum };

      const res = await this.$store.dispatch("GET_SCHOOL_CLASS_ID_LIST", data);

      this.idlist = res.sort((a, b) => a.attendanceNumber - b.attendanceNumber);
      this.popupindex = index;
      //this.$store.state.classInfoPopupView = true;
    },
    offclassdetail() {
      this.idmodal = false;
      this.idlist = [];
    },
    vacationtext(bool) {
      if (bool) {
        return "방학 중지";
      } else {
        return "해당 없음";
      }
    },
    vacationstop(j, bool) {
      j.vacation = bool;
      this.vacationselect = -1;
      return;
    },
    vacationon(i) {
      if (!this.iscontractmodify) {
        return;
      }
      if (this.vacationselect != -1) {
        this.vacationselect = -1;
      } else {
        this.vacationselect = i;
      }
    },
    async updateSchoolInfo() {
      if (window.confirm("학교 정보를 업데이트 하시겠습니까?")) {
        const schoolCode = this.$route.params.code;

        const {
          payType,
          payStatus,
          accountStatus,
          affiliate,
          language,
          payPlan,
        } = this.school;

        const data = {
          schoolCode,
          payType: payType || "-",
          payStatus: payStatus || "-",
          accountStatus: accountStatus || "-",
          affiliate: affiliate || "-",
          language,
        };

        const res = await this.$store.dispatch("UPDATE_SCHOOL_INFO", data);
        await changeSchoolPayPlan({ schoolCode, payPlan });

        if (res) {
          alert("학교 정보가 업데이트 되었습니다.");
          this.payStatus = payStatus;
          this.accountStatus = accountStatus;
          this.isschoolmodify = false;
          this.previousPlan = payPlan;
          return;
        } else {
          alert("학교 정보 업데이트 중 오류가 발생하였습니다.");
          this.isschoolmodify = false;
          this.school.payPlan = this.previousPlan;
          return;
        }
      } else {
        return;
      }
    },
    async updateSchoolContract() {
      if (window.confirm("계약 정보를 업데이트 하시겠습니까?")) {
        const schoolCode = this.$route.params.code;
        const contractList = this.contracts;

        for (let i = 0; i < contractList.length; i++) {
          contractList[i].startDate = this.datetext(contractList[i].startDate);
          contractList[i].expiryDate = this.datetext(contractList[i].endDate);
        }

        const data = {
          schoolCode,
          contractList,
        };

        const res = await this.$store.dispatch(
          "UPDATE_SCHOOL_CONTRACT_INFO",
          data
        );
        if (res) {
          alert("계약 정보가 업데이트 되었습니다.");
          this.iscontractmodify = false;
          return;
        } else {
          alert("계약 정보 업데이트 중 오류가 발생하였습니다.");
          this.iscontractmodify = false;
          return;
        }
      } else {
        return;
      }
    },
    viewdateon(index) {
      if (!this.iscontractmodify) {
        return;
      }
      this.viewDate[index] = !this.viewDate[index];
    },
    schoolinfomodify() {
      this.isschoolmodify = true;
    },
    memomodify() {
      this.ismemomodify = true;
    },
    contractmodify() {
      this.iscontractmodify = true;
    },
    cancelschoolinfomodify() {
      this.isschoolmodify = false;
      this.planChangeFlag = false;
      this.payopen = false;
      this.accountopen = false;
      this.school.payPlan = this.previousPlan;
      this.previousPlan = "";
    },
    cancelmemomodify() {
      this.ismemomodify = false;
    },
    cancelcontractmodify() {
      this.iscontractmodify = false;
    },
    datetext(str) {
      return dayjs(str).format("YYYY/MM/DD");
    },
    gopay() {
      if (!this.isschoolmodify) {
        return;
      } else {
        this.accountopen = false;

        this.payopen = !this.payopen;
        return;
      }
    },
    goaccount() {
      if (!this.isschoolmodify) {
        return;
      } else {
        this.payopen = false;

        this.accountopen = !this.accountopen;
        return;
      }
    },
    modifypaystatus(status) {
      this.school.payStatus = status;
      this.gopay();
    },
    modifyaccountstatus(status) {
      this.school.accountStatus = status;

      this.accountopen = false;
    },
    languageprint(n) {
      if (n === 0) {
        return "한국어";
      } else if (n === 1) {
        return "베트남어";
      } else {
        return "한국어";
      }
    },
    changelanguage() {
      if (!this.isschoolmodify) {
        return;
      }

      if (this.school.language === 0) {
        this.school.language = 1;
      } else {
        this.school.language = 0;
      }
    },
  },
  watch: {
    payStatus() {
      this.payopen = false;
      this.accountopen = false;
    },
    accountStatus() {
      this.payopen = false;
      this.accountopen = false;
    },
  },
};
</script>

<style scoped>
.defaultbtn {
  font-size: 13px;
  font-family: notosanslight;
  font-weight: bold;
  color: white;
}

.editbtn {
  background-color: #7f7f7f;
}

.checkbtn {
  background-color: #0e7bcb;
}

.tabletd {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  padding: 8px;
  font-size: 13px;
  background-color: rgb(240, 240, 240);
  border-right: none;
}

.tabletd2 {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  font-weight: bold;
  padding: 8px;
  font-size: 14px;
  border-right: none;
  border-top: none;
}

.tdlast {
  border-right: 1px solid #7f7f7f;
}

#vacationoption1 {
  border: 1px solid black;
  border-bottom: none;
  cursor: pointer;
}

#vacationoption2 {
  border: 1px solid black;
  cursor: pointer;
}

#vacationoption1:hover {
  background-color: rgb(240, 240, 240);
}

#vacationoption2:hover {
  background-color: rgb(240, 240, 240);
}

.accountoption1 {
  border: 1px solid black;
  cursor: pointer;
}

.accountoption2 {
  border: 1px solid black;
  cursor: pointer;
}

.accountoption3 {
  border: 1px solid black;
  cursor: pointer;
  background-color: white;
}

.accountoption4 {
  border: 1px solid black;
  cursor: pointer;
}

.accountoption1:hover {
  background-color: rgb(240, 240, 240);
}

.accountoption2:hover {
  background-color: rgb(240, 240, 240);
}

.accountoption3:hover {
  background-color: rgb(240, 240, 240);
}

.accountoption4:hover {
  background-color: rgb(240, 240, 240);
}

#payoption1 {
  border: 1px solid black;
  cursor: pointer;
}

#payoption2 {
  border: 1px solid black;
  cursor: pointer;
}

#payoption3 {
  border: 1px solid black;
  cursor: pointer;
  background-color: white;
}

#payoption4 {
  border: 1px solid black;
  cursor: pointer;
}

#payoption1:hover {
  background-color: rgb(240, 240, 240);
}

#payoption2:hover {
  background-color: rgb(240, 240, 240);
}

#payoption3:hover {
  background-color: rgb(240, 240, 240);
}

#payoption4:hover {
  background-color: rgb(240, 240, 240);
}

.iddetailbtn {
  font-size: 14px;
  border: none;
  font-weight: bold;
  background-color: white;
  color: black;
  width: 70px;
  height: 100%;
  border-radius: 20px;
}

.iddetailbtnselected {
  background-color: rgb(170, 170, 170);
}

.iddetailbtn:hover {
  background-color: rgb(170, 170, 170);
}

.payPlanContent {
  width: 100px;
  height: 205px;
  background-color: white;
  position: absolute;
  border: 1px solid black;
  margin-left: -10px;
}

.payPlanContentElement {
  width: 100%;
  height: 40px;
}

.payPlanContentElement:hover {
  background-color: rgb(240, 240, 240);
}
</style>