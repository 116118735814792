import { instance, instanceAuth, socketinstance } from './url'

const maintenance = async () => {
  return await instance
    .get('server/maintenance', { credentials: true })
    .catch((err) => {
      console.log(err.response)
    })
}

const login = async (loginData) => {
  return await instance
    .post('lms/login', loginData, { credentials: true, timeout: 10000 })
    .catch((err) => {
      return false
    })
}
const userInfo = async () => {
  return await instanceAuth
    .get('/users', { credentials: true })
    .catch((err) => {
      return err.response
    })
} // 단원 정보 불러오기
const lessonInfo = async () => {
  return await instanceAuth
    .get('/lms/lesson-list', { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const lessonaverageprogress = async () => {
  return await instanceAuth
    .get('/lms/class-lesson-progress', { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const lessonStudentInfo = async (index) => {
  return await instanceAuth
    .get(`/lms/student-studylist/${index}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const lessonStudentDetailInfo = async (id, lesson) => {
  return await instanceAuth
    .get(`/lms/student-detail/${id}/${lesson}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const lessonSectionAverage = async (lesson) => {
  return await instanceAuth
    .get(`/lms/class-section-correct/${lesson}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const lessonChatbot = async (id, lesson) => {
  return await instanceAuth
    .get(`/lms/user/chatbot/${id}/${lesson}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const studenttotalinfo = async (id) => {
  return await instanceAuth
    .get(`/lms/student-detail/${id}/all`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const todayclass = async () => {
  return await instanceAuth
    .get(`/lms/class-studylist`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const chapteraverage = async () => {
  return await instanceAuth
    .get(`/lms/student-detail`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const sectionclassaverage = async (lesson) => {
  return await instanceAuth
    .get(`/lms/class-section-correct/${lesson}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const sendfeedback = async (message) => {
  return await instanceAuth
    .post(`/lms/user/feedback`, message, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const getsendfeedback = async (id) => {
  return await instanceAuth
    .get(`/lms/user/feedback/${id}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const openlessoninfo = async () => {
  return await instanceAuth
    .get(`/lms/class-lesson-list`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const openlesson = async (data) => {
  return await instanceAuth
    .post(`/lms/change/lesson-status`, data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const openmission = async (data) => {
  return await instanceAuth
    .post(`/lms/open/mission`, data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const deletemission = async () => {
  return await instanceAuth
    .delete(`/lms/delete/mission`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const passwordinit = async (id) => {
  return await instanceAuth
    .put(`/lms/${id}/reset/password`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const passwordchange = async (data) => {
  return await instanceAuth
    .post(`/users/password/lms`, data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
const test = async () => {
  return await socketinstance
    .get(`/user-list`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const lessondetailinfo = async (lesson) => {
  return await instanceAuth
    .get(`/lms/lesson-detail/${lesson}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const restday = async () => {
  return await instanceAuth
    .get('/lms/class-period', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const lastdaylist = async () => {
  return await instanceAuth
    .get('/lms/last-edit', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const lastdaysave = async () => {
  return await instanceAuth
    .get('/lms/update/terminate-studyTime', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const missionlist = async () => {
  return await instanceAuth
    .get('/tasks/missions', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const free_chat = async (userId, date) => {
  return await instanceAuth
    .get(`/lms/user/gpt/${userId}/${date}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const modify_nickname = async (data) => {
  return await instanceAuth
    .post('/lms/update/nickname', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const sendmessage = async (data) => {
  return await instanceAuth
    .post('/mail/auth/send', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const checkauth = async (data) => {
  return await instanceAuth
    .post('/mail/auth/check', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const agreement = async () => {
  return await instanceAuth
    .post('/lms/agreement', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const studentmanagelog = async (data) => {
  return await instanceAuth
    .post('/log/studentManage', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const classsettinglog = async (data) => {
  return await instanceAuth
    .post('/log/classSet', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const classmanagelog = async (data) => {
  return await instanceAuth
    .post('/log/classLook', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const studentmorelog = async (data) => {
  return await instanceAuth
    .post('/log/studentManageMore', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const guidelog = async () => {
  return await instanceAuth
    .post('/lms/guide', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const asklog = async () => {
  return await instanceAuth
    .post('/log/help', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const lmslogoutlog = async () => {
  return await instanceAuth
    .post('/log/lms/logout', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const classlist = async () => {
  return await instanceAuth
    .get('/lms/classList', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const changeclass = async (data) => {
  return await instanceAuth
    .post('/lms/set-class', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getexistmission = async (data) => {
  return await instanceAuth
    .get('/lms/class-teacher-mission', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const changeSchoolPayPlan = async (data) => {
  return await instanceAuth
    .post('/schools/change/payPlan', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

export {
  changeSchoolPayPlan,
  maintenance,
  login,
  userInfo,
  lessonInfo,
  lessonaverageprogress,
  lessonStudentInfo,
  lessonStudentDetailInfo,
  lessonSectionAverage,
  lessonChatbot,
  studenttotalinfo,
  todayclass,
  chapteraverage,
  sectionclassaverage,
  sendfeedback,
  getsendfeedback,
  openlessoninfo,
  openlesson,
  openmission,
  deletemission,
  passwordinit,
  passwordchange,
  lessondetailinfo,
  test,
  restday,
  lastdaylist,
  lastdaysave,
  missionlist,
  free_chat,
  modify_nickname,
  sendmessage,
  checkauth,
  agreement,
  studentmanagelog,
  classsettinglog,
  classmanagelog,
  studentmorelog,
  guidelog,
  asklog,
  lmslogoutlog,
  classlist,
  changeclass,
  getexistmission
}
