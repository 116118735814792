import { instanceAuth } from './url'

const login = async (loginData) => {
  return await instanceAuth
    .post('/users/login', loginData, { credentials: true, timeout: 10000 })
    .catch((err) => {
      return false
    })
}

const schoolstatus = async () => {
  return await instanceAuth
    .get('/schools/status', { credentials: true })
    .catch((err) => {
      return false
    })
}

const estimateRegister = async (data) => {
  return await instanceAuth
    .post('/schools/estimante', data, { credentials: true })
    .catch((err) => {
      return false
    })
}

const getestimatesample = async (data) => {
  return await instanceAuth
    .post('/schools/estimate/sample', data, { credentials: true })
    .catch((err) => {
      return ''
    })
}

const getschoolcontact = async (schoolCode) => {
  return await instanceAuth
    .get(`/schools/contact/${schoolCode}`, { credentials: true })
    .catch((err) => {
      return []
    })
}

const saveestimatefile = async (data) => {
  return await instanceAuth
    .post('/storage/estimate/save', data, { credentials: true })
    .catch((err) => {})
}

const sendestimatemail = async (data) => {
  return await instanceAuth
    .post('/schools/mail/estimate', data, { credentials: true })
    .catch((err) => {})
}

const sendaccountguidemail = async (data) => {
  return await instanceAuth
    .post('/schools/mail/account-guide', data, { credentials: true })
    .catch((err) => {})
}

const sendaccountguidesms = async (data) => {
  return await instanceAuth
    .post('/schools/sms/account-guide', data, { credentials: true })
    .catch((err) => {})
}

const getschoollist = async () => {
  return await instanceAuth
    .get('/schools/', { credentials: true })
    .catch((err) => {})
}

const getschooldetail = async (schoolCode) => {
  return await instanceAuth
    .get(`/schools/info/${schoolCode}`, { credentials: true })
    .catch((err) => {})
}

const updateschoolmemo = async (data) => {
  return await instanceAuth
    .post('/schools/update/memo', data, { credentials: true })
    .catch((err) => {})
}

const getschoolmemo = async (schoolCode) => {
  return await instanceAuth
    .get(`/schools/memo/${schoolCode}`, { credentials: true })
    .catch((err) => {})
}

const sendestimatesms = async (data) => {
  return await instanceAuth
    .post('/schools/tel/estimate', data, { credentials: true })
    .catch((err) => {})
}

const estimatesend = async (data) => {
  return await instanceAuth
    .post('/schools/send/estimante', data, { credentials: true })
    .catch((err) => {})
}

const getestimatelist = async (code) => {
  return await instanceAuth
    .get(`/schools/estimante/${code}`, { credentials: true })
    .catch((err) => {})
}

const getsendestimatelist = async (code) => {
  return await instanceAuth
    .get(`/schools/send-estimante/${code}`, { credentials: true })
    .catch((err) => {})
}

const updatecontact = async (data) => {
  return await instanceAuth
    .post('/schools/update/contact', data, { credentials: true })
    .catch((err) => {})
}

const schoolclasslist = async (code) => {
  return await instanceAuth
    .get(`/schools/class/list/${code}`, { credentials: true })
    .catch((err) => {})
}

const schoolclassidlist = async (data) => {
  const { schoolCode, grade, classNum } = data
  return await instanceAuth
    .get(`/users/user/${schoolCode}/${grade}/${classNum}`, {
      credentials: true
    })
    .catch((err) => {})
}

const registerschool = async (data) => {
  data.language = 0

  return await instanceAuth
    .post('/schools/create', data, { credentials: true })
    .catch((err) => {})
}

const accountfilecheck = async (data) => {
  return await instanceAuth
    .post('/schools/excel', data, { credentials: true })
    .catch((err) => {})
}

const getsearchlist = async () => {
  return await instanceAuth
    .get(`/schools/search/list`, { credentials: true })
    .catch((err) => {})
}

const postaccountfile = async (data) => {
  return await instanceAuth
    .post(`/users/excel`, data, {
      credentials: true
    })
    .catch((err) => {})
}

const getuserbytype = async (code) => {
  return await instanceAuth
    .get(`/users/user/${code}`, {
      credentials: true
    })
    .catch((err) => {})
}

// 방학중지 여부 설정 post
const poststopvacation = async (data) => {
  return await instanceAuth
    .post(`/schools/stop/vacation`, data, {
      credentials: true
    })
    .catch((err) => {
      return err
    })
}

// 비밀번호 변경 post
const postpasswordcustom = async (data) => {
  return await instanceAuth
    .post(`/users/account/password/custom`, data, {
      credentials: true
    })
    .catch((err) => {
      return err
    })
}

// 메일 템플릿 받기 get
const getmailtemplate = async (code) => {
  if (!code) {
    return ''
  }
  return await instanceAuth
    .get(`/schools/mail/account-issue/get/${code}`, {
      credentials: true
    })
    .catch((err) => {
      return err
    })
}

// 문자 템플릿 받기 get
const getsmstemplate = async () => {
  return await instanceAuth
    .get(`/schools/sms/account-issue/get`, { credentials: true })
    .catch((err) => {
      return err
    })
}

const getusername = async () => {
  return await instanceAuth
    .get(`/users/name`, { credentials: true })
    .catch((err) => {
      return err
    })
}

const getaccountissuerecord = async (data) => {
  return await instanceAuth
    .post(`/schools/account-issue/record/get`, data, { credentials: true })
    .catch((err) => {
      return err
    })
}

const setaccountissuerecord = async (data) => {
  return await instanceAuth
    .post(`/schools/account-issue/record/set`, data, { credentials: true })
    .catch((err) => {
      return err
    })
}

const gettransferstudentlength = async () => {
  return await instanceAuth
    .get(`/schools/transfer/apply/length`, { credentials: true })
    .catch((err) => {})
}

const gettransferstudentlist = async (page) => {
  return await instanceAuth
    .get(`/schools/transfer/apply/${page}`, { credentials: true })
    .catch((err) => {})
}

const getprocessedtransferstudentlength = async (schoolCode) => {
  return await instanceAuth
    .get(`/schools/transfer/apply/old/length/${schoolCode}`, {
      credentials: true
    })
    .catch((err) => {})
}

const getprocessedtransferstudentlist = async (data) => {
  const { schoolCode, page } = data
  return await instanceAuth
    .get(`/schools/transfer/apply/old/${schoolCode}/${page}`, {
      credentials: true
    })
    .catch((err) => {})
}

const getprocesshistory = async (schoolCode) => {
  return await instanceAuth
    .get(`/schools/transfer/apply/history/${schoolCode}`, { credentials: true })
    .catch((err) => {})
}

const postMailAccountIssue = async (data) => {
  return await instanceAuth
    .post(`/schools/mail/account-issue/complete`, data, { credentials: true })
    .catch((err) => {})
}

const postSmsAccountIssue = async (data) => {
  return await instanceAuth
    .post(`/schools/sms/account-issue/complete`, data, { credentials: true })
    .catch((err) => {})
}

const getvactionstate = async (schoolCode) => {
  return await instanceAuth
    .get(`/schools/vacation/${schoolCode}`, { credentials: true })
    .catch((err) => {
      return err
    })
}

const postTransferApply = async (data) => {
  return await instanceAuth
    .post(`/schools/transfer/apply`, data, { credentials: true })
    .catch((err) => {})
}

const getschoolcontractinfo = async (schoolCode) => {
  return await instanceAuth
    .get(`/schools/info/${schoolCode}/year/contract`, { credentials: true })
    .catch((err) => {
      return err
    })
}

const updateschoolinfo = async (data) => {
  return await instanceAuth
    .post(`/schools/update/info/basic`, data, { credentials: true })
    .catch((err) => {})
}

const updateschoolcontract = async (data) => {
  return await instanceAuth
    .post(`/schools/update/info/contract`, data, { credentials: true })
    .catch((err) => {})
}

const transferapplysms = async (data) => {
  return await instanceAuth
    .post('/schools/transfer/apply/sms', data, { credentials: true })
    .catch((err) => {})
}

const changeschoolpaystatus = async (data) => {
  return await instanceAuth
    .post('/schools/change/status/pay', data, { credentials: true })
    .catch((err) => {})
}

const changeschoolaccountstatus = async (data) => {
  return await instanceAuth
    .post('/schools/change/status/account', data, { credentials: true })
    .catch((err) => {})
}

const changePayPlan = async (data) => {
  return await instanceAuth
    .put('/schools/change/payPlan', data, { credentials: true })
    .catch((err) => {})
}

const createallguest = async () => {
  return await instanceAuth
    .post('/users/create/all-guest', { credentials: true })
    .catch((err) => {})
}

const downloadAllContacts = async () => {
  return await instanceAuth
    .get('/schools/download/contact', { credentials: true })
    .catch((err) => {})
}

export {
  downloadAllContacts,
  login,
  schoolstatus,
  estimateRegister,
  getestimatesample,
  getschoolcontact,
  saveestimatefile,
  sendestimatemail,
  getschoollist,
  getschooldetail,
  updateschoolmemo,
  getschoolmemo,
  sendaccountguidemail,
  sendestimatesms,
  estimatesend,
  getestimatelist,
  getsendestimatelist,
  updatecontact,
  schoolclasslist,
  schoolclassidlist,
  registerschool,
  accountfilecheck,
  getsearchlist,
  postaccountfile,
  getuserbytype,
  poststopvacation,
  postpasswordcustom,
  getmailtemplate,
  getsmstemplate,
  getusername,
  getaccountissuerecord,
  setaccountissuerecord,
  gettransferstudentlength,
  gettransferstudentlist,
  getprocessedtransferstudentlength,
  getprocessedtransferstudentlist,
  getprocesshistory,
  postMailAccountIssue,
  postSmsAccountIssue,
  getvactionstate,
  postTransferApply,
  getschoolcontractinfo,
  updateschoolcontract,
  updateschoolinfo,
  sendaccountguidesms,
  transferapplysms,
  changeschoolpaystatus,
  changeschoolaccountstatus,
  changePayPlan,
  createallguest
}
